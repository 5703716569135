import * as React from "react";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";


import Home from '../pages/home/home';


const NotFound = React.lazy(() =>import('../components/404/404'));



export const AppRouter = () => {
    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
                <div>
                    {/*<div className="NavbarMenu">
                        <Link to="/" className="NavbarMenuItem">Home</Link>
                    </div>*/}
                    {/*Switch component renders first match on route, if not, it renders not found page.*/}
                    <div className="content">
                        <div className="content-centered">
                            <Switch>
                                <Route path="/" exact component={Home}/>
                                <Route component={NotFound} exact/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        </React.Suspense>
    );
};
