import * as React from 'react';
import {AppRouter} from './router';


const App = () => (
    <>
    <AppRouter/>
    </>
);


export default App;