import React from 'react';
import { Link } from "react-router-dom";

import CatImage from './luckystarcatgifedit.gif';

import './home.css';

const Home = () => {
    return (
        <div className="page">
            <img src={CatImage} />
        </div>
    );
};

export default Home;